<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
        
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>

                        <div class="card" style="z-index: 2;">
                          <div class="card-header actions-toolbar border-0">
                            <div class="row justify-content-between align-items-center">
                                <div class="col" style="padding-top: 0px;padding-bottom: 0px;">
                                    <h2 class="d-inline-block mb-0 card-title">場域管理者帳號設定</h2>
                                </div>
                            </div>
                          </div>
                          <br/>
                            <!-- ==========主要內容區========== -->
                            <v-form ref="form" class="px-12" v-model="isValid">
                              <v-row>
                                <v-subheader>
                                  場域管理者帳號設定
                                </v-subheader>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>   
                              <v-row>
                                <v-col sm="2" style="margin-bottom: 0px;">
                                  <v-switch
                                    v-model="memberData.status"
                                    label="啟用"
                                    color="success"
                                    hide-details
                                    :readonly = "loginAuth.role !== 'S' && loginAuth.role !== 'O'"
                                  ></v-switch>
                                </v-col>
                                <v-col>
                                  <v-text-field v-if="addMode" 
                                    label="*帳號" 
                                    outlined v-model="memberData.account" 
                                    :rules="[rules.required]" required 
                                    hint="*必填" persistent-hint>
                                  </v-text-field>
                                  <v-text-field v-else 
                                    label="帳號" 
                                    outlined v-model="memberData.account" 
                                    filled readonly>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-select
                                      :items="orgItems"
                                      label="*事業單位名稱"
                                      v-model="memberData.orgId"
                                      dense hint="*必填"
                                      :readonly = "loginAuth.role !== 'S'"
                                      @input="getSubUnitList"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row v-if="addMode">
                                <v-col sm="6">
                                  <v-text-field
                                    v-model="memberData.password"
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[rules.required]" required
                                    :type="show1 ? 'text' : 'password'"
                                    label="*密碼"
                                    hint="*必填" persistent-hint
                                    @click:append="show1 = !show1"
                                  ></v-text-field>
                                </v-col>
                                <v-col sm="6">
                                  <v-text-field
                                    v-model="memberData.passwordConfirm"
                                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[rules.required]" required
                                    :type="show2 ? 'text' : 'password'"
                                    label="*確認密碼"
                                    hint="*必填" persistent-hint
                                    @click:append="show2 = !show2"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row v-else>
                                <v-col sm="6">
                                  <v-text-field
                                    v-model="memberData.password"
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show1 ? 'text' : 'password'"
                                    label="密碼"
                                    @click:append="show1 = !show1"
                                  ></v-text-field>
                                </v-col>
                                <v-col sm="6">
                                  <v-text-field
                                    v-model="memberData.passwordConfirm"
                                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show2 ? 'text' : 'password'"
                                    label="確認密碼"
                                    @click:append="show2 = !show2"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col sm="6">
                                  <v-text-field label="姓名" outlined v-model="memberData.name" :rules="[rules.required]" required ></v-text-field>
                                </v-col>
                                <v-col sm="6">
                                  <v-text-field label="電話" outlined v-model="memberData.phone"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col sm="6">
                                  <v-text-field label="職稱" outlined v-model="memberData.jobTitle"></v-text-field>
                                </v-col>
                                <v-col sm="6">
                                  <v-text-field label="工作性質" outlined v-model="memberData.jobDescription"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field label="email" outlined v-model="memberData.email"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-subheader>
                                  子單位
                                </v-subheader>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>                      
                              <v-row>
                                <v-col>
                                  <ul>
                                    <li v-for="(item, index) in subUnitList" :key="index" style="width: 33%;float:left;list-style-type:none;">
                                        <v-checkbox v-if="item.orgId === memberData.orgId"
                                            v-model="subUnitRelationshipList"
                                            :label="item.suName"
                                            :value="item"
                                            :readonly = "loginAuth.role !== 'S' && loginAuth.role !== 'O'"
                                        ></v-checkbox>
                                    </li>
                                  </ul>
                                </v-col>
                              </v-row>
                              <v-row justify="center">
                                    <v-btn class="mr-4 white--text" min-width="100" color="#37906C" large @click="submitData" :disabled="saveStatus || !isValid">
                                      儲存
                                    </v-btn>
                                    <v-btn class="mr-4 white--text" color="#4D4D4D" large @click="toCancel">
                                      回上一頁
                                    </v-btn>
                              </v-row>
                            </v-form>
                            <!-- ==========主要內容區========== -->
                            <br/>
                        </div>
                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
        <loadingMask :showLoading="isLoading" style="z-index:9999"></loadingMask>

    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin';
import loadingMask from '@/components/public/loadingMask.vue'
import {getMemberData, saveMember, addMember, getOrgnizationList, getOrgnizationData} from "@/api/hyirecoApi";

export default {
  name: 'memberList',
  mixins: [commonMixin],
  created(){
    this.authValidate();
    console.log("loginAuth = " + JSON.stringify(this.loginAuth));
    this.auth = {
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getTokenWithSession(),
      }
    };
    console.log(this.$route.matched);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  mounted(){
    this.userName = this.loginAuth.memberName;
    if (this.$route.params.uuid === "init") { 
      this.memberData.status = "Y";
      if (this.loginAuth.role !== "S") {
        this.memberData.orgId = this.loginAuth.orgnization.orgId;
        this.getSubUnitList();
      }
    } else {
      this.addMode = false;
      this.getmemberData();
    }
    this.getOrgDataList();
  },
  methods: {
    getmemberData: function(){
      this.isLoading = true;
      var vm = this;
      getMemberData(vm.$route.params.uuid, vm.auth).then(function(response) {
          if ("200" == response.status) { 
            var responseData = response.data;
            console.log("responseData getMemberData = " + JSON.stringify(responseData));

            vm.memberData = responseData.userAccount;
            vm.memberData.status = vm.memberData.status === "Y" ? true : false;
            vm.memberData.password = "";
            vm.memberData.passwordConfirm = "";

            vm.subUnitList = responseData.userAccount.orgnization.subUnitList;
            vm.subUnitRelationshipList = responseData.userAccount.subUnitRelationshipList.map(function(item) {
              return item.subUnit;
            });
          }
      });
      this.isLoading = false;
    },
    getSubUnitList: function() {
      var vm = this;
      //vm.subUnitRelationshipList = [];
      vm.subUnitList = [];
      if (this.loginAuth.role === "S") {
        if (vm.memberData.orgId !== null) {
          getOrgnizationData(vm.memberData.orgId, vm.auth).then(function(response) {
            if ("200" == response.status) { 
              var responseData = response.data;
              vm.subUnitList = responseData.orgnization.subUnitList;
            }
          });
        }
      } else {
        // vm.subUnitList = this.loginAuth.subUnitRelationshipList.map(function(item) {
        //   var obj = new Object();
        //   obj.text = item.suName;
        //   obj.value = item.suName;
        //   return obj;
        // });
        vm.subUnitList = this.loginAuth.orgnization.subUnitList;

        console.log("vm.subUnitList = " + JSON.stringify(vm.subUnitList));
      }

    },
    getOrgDataList: function() { 
        var vm = this;
        vm.isLoading = true;
        
        getOrgnizationList(vm.queryRequest, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                //console.log("response = " + JSON.stringify(responseData));
                
                if (responseData.statusCode === "1") {
                    var datalist = responseData.queryPager.resultList;
                    vm.orgItems = datalist.map(function(item){
                        item.text = item.name;
                        item.value = item.orgId;
                        return item;
                    });
                }
            }
            vm.isLoading = false;
        });
       
    },
    submitData: function() {
      var vm = this;

      if(vm.memberData.passwordConfirm !== vm.memberData.password){
        vm.$swal("密碼輸入不正確");
        return false;
      }

      vm.isLoading = true;
      vm.saveStatus = true;

      var apiObj = saveMember;
      if (this.$route.params.uuid === "init") { 
        apiObj = addMember;
      }
      
      vm.memberData.subUnitRelationshipList = vm.subUnitRelationshipList;

      vm.memberData.status = vm.memberData.status ? "Y" : "N";
      var dataObj = new Object();
      dataObj.userAccount = vm.memberData;
      dataObj.userAccount.role = "J";
      dataObj.passwordConfirm = vm.memberData.passwordConfirm;

      console.log("apiObj = " + apiObj);
      console.log("dataObj = " + JSON.stringify(dataObj));
      apiObj(dataObj, vm.auth).then(function(response) {
          if ("200" == response.status) { 
            var responseData = response.data;
            vm.$swal(responseData.message);
            if (responseData.statusCode === "1") {
              vm.toCancel();
            }
            console.log("responseData = " + JSON.stringify(response));
            vm.saveStatus = false;
            vm.isLoading = false;
          }
      });
    },
    toCancel: function () {
      var vm = this;
      var path = "";
      var next = vm.$route.meta.authority.filter(function(item){
        return item.role === vm.loginAuth.role;
      });

      if (next.length > 0) {
        path = next[0].returnPath;
      } else {
        path = "/index";
      }

      this.$router.push({ path: path });
      // if (this.loginAuth.role === "S") {
      //   this.$router.push({ path: "/member/jsMemberList" });
      // } else {
      //   this.$router.push({ path: "/index" });
      // }
    },
  },
  watch: {
  },
  data: () => ({
    rules: {
      required: value => !!value || '必填欄位',
      requiredForLabor: value => !!value || '若沒有請填 無',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || '不符規格的 e-mail 信箱'
      },
    },
    addMode: true,
    orgItems: [],
    subUnitList: [],
    subUnitRelationshipList:[],
    userName: "",
    memberData: {},
    isLoading: false,
    auth: {},
    saveStatus: false,
    isValid: true,
    show1: false,
    show2: false,
    queryRequest: {
        queryObject: {},
        queryPager: {
            currentPage: 1,
            pageSize: -1,
        },
    },
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>
.v-input__slot > .v-label
{
    margin-bottom: 0px;
}
.v-subheader {
    color: rgb(34, 34, 34) !important;
    font-size: large;
}
</style>